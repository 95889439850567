<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>

          <!-- Add Record -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              :disabled="isAddRecordActive"
              @click="navigateToHabitConfiguration(0)"
            >
              <span> {{ $t('ADD_HABIT') }} </span>
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
          </b-col>

          <!-- Category-->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                v-model="templateCategory"
                :options="allCategories"
                label="name"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                :placeholder="$t('SELECT_CATEGORY')"
                @input="fetchTemplates(templateCategory)"
              />
            </div>
          </b-col>
          <!-- Template-->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                v-model="template"
                :options="templates"
                label="name"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                :placeholder="$t('SELECT_TEMPLATE')"
                @input="fetchTemplatesTask(template)"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card :title="$t('HABIT_BEHAVIOUR_TEMPLATE')">
      <app-timeline
        v-for="(task,index) in templatesTask"
        :id="task.taskID.toString()"
        :key="task.taskID"
      >
        <!-- Sub task-->
        <app-timeline-item
          :id="task.taskID.toString()"
          :key="index"
          variant="primary"
          icon="FileTextIcon"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6><b-avatar
              size="30"
              :text="task.sortorder.toString()"
              variant="light-primary"
            />{{ task.taskbeskrivning }} ( {{ task.points }}) </h6>
            <div class="text-nowrap">
              <feather-icon
                :id="`game-row-${task.taskID}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer mx-1"
                size="16"
                @click="navigateToHabitConfiguration(task.taskID)"
              />
              <feather-icon
                :id="`game-row-${task.taskID}-send-icon`"
                icon="MonitorIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="navigateMediaConfiguration(templateID,task.taskID,0)"
              />
              <feather-icon
                :id="`game-row-${task.taskID}-send-icon`"
                icon="PlusIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="navigateToBehaviourConfiguration(task.taskID,0)"
              />
              <feather-icon
                :id="`game-row-${task.taskID}-send-icon`"
                icon="Trash2Icon"
                class="cursor-pointer mr-1"
                size="16"
                @click="removeTask(task)"
              />
            </div>
          </div>
          <b-collapse
            :id="task.taskID.toString()"
            visible
          >
            <b-list-group
              flush
              class="mt-1"
            >
              <b-list-group-item
                v-for="(subTask,i) in task.template_task_subtasks"
                :id="subTask.template_subtask.subtaskID.toString()"
                :key="i"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span> <b-avatar
                  size="30"
                  :text="subTask.sortorder.toString()"
                  variant="light-warning"
                />{{ subTask.template_subtask.subtaskstring }} ({{ subTask.template_subtask.subtaskpoint }})</span>
                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="navigateToBehaviourConfiguration(task.taskID,subTask.template_subtask.subtaskID)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t('BUTTON.EDIT') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="navigateMediaConfiguration(templateID,0,subTask.template_subtask.subtaskID)">
                    <feather-icon icon="MonitorIcon" />
                    <span class="align-middle ml-50">{{ $t('MEDIA') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="removeSubTask(subTask.template_subtask)">
                    <feather-icon icon="Trash2Icon" />
                    <span class="align-middle ml-50">{{ $t('BUTTON.DELETE') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
        </app-timeline-item>
      </app-timeline>
    </b-card>
  </div>
</template>

<script>

import {
  BListGroup,
  BListGroupItem,
  VBToggle,
  VBTooltip,
  BCard,
  BCollapse,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { mapActions, mapState } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

// NEW -----------------------------------------------------------------------

export default {
  components: {
    BRow,
    BCol,
    BButton,
    vSelect,
    BCard,
    AppTimeline,
    AppTimelineItem,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BDropdown,
    BDropdownItem,
    BAvatar,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      templates: [],
      templatesTask: [],
      templateCategory: {
        ID: 0,
        name: 'Select Category',
        color: '',
        description: '',
      },
      template: '',
      templateID: 0,
    }
  },
  setup() {
    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    const isAddRecordActive = ref(true)

    return {
      isAddRecordActive,
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  created() {
    this.fetchTemplatesCategory()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('template', ['allCategories']),
  },
  methods: {
    ...mapActions('template', ['fetchAllCategories', 'fetchAllTemplates', 'fetchAllTemplateTask', 'deleteTask', 'deleteSubTask']),
    navigateToHabitConfiguration(data) {
      const id = data
      const categoryId = this.templateCategory.ID
      const templateId = this.template.ID
      this.$router.push({
        name: 'contents-habit-configuration-id',
        params: { id },
        query: { templateId, categoryId },
      })
    },
    navigateToBehaviourConfiguration(taskID, subTaskID) {
      const id = taskID
      const subtaskId = subTaskID
      this.$router.push({
        name: 'contents-behaviour-configuration-id',
        params: { id },
        query: { subtaskId },
      })
    },
    navigateMediaConfiguration(templateID, tId, sId) {
      const id = templateID
      const taskID = tId
      const subTaskID = sId
      this.$router.push({
        name: 'media-list-id',
        params: { id },
        query: { taskID, subTaskID, objectID: 'template' },
      })
    },
    fetchTemplatesCategory() {
      this.fetchAllCategories().then(response => {
        if (response) {
          this.isAddRecordActive = true
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
    fetchTemplates(e) {
      this.templates = []
      this.templatesTask = []
      const params = `?category_id=${e.ID}&type=game`
      this.fetchAllTemplates(params).then(response => {
        if (response) {
          this.templates = response
          this.template = ''
          this.isAddRecordActive = true
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
    fetchTemplatesTask(e) {
      this.templatesTask = []
      this.templateID = e.ID
      this.fetchAllTemplateTask(e.ID).then(response => {
        if (response) {
          this.templatesTask = response
          this.isAddRecordActive = false
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
    removeTask(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.taskbeskrivning}   ${this.$i18n.t('HABIT')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.onDeleteTask(data.taskID)
          }
        })
    },
    onDeleteTask(data) {
      if (data) {
        this.deleteTask(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.HABIT_DELETED'))
            this.fetchTemplatesTask(this.template)
          }
        }).catch(() => {
          this.errorMessage(this.$i18n.t('MESSAGE.PERFORMED_HABIT_EXIST'))
        })
      }
    },
    onDeleteSubTask(data) {
      if (data) {
        this.deleteSubTask(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.BEHAVIOUR_DELETED'))
            this.fetchTemplatesTask(this.template)
          }
        }).catch(() => {
          this.errorMessage(this.$i18n.t('MESSAGE.PERFORMED_BEHAVIOUR_EXIST'))
        })
      }
    },
    removeSubTask(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.subtaskstring}   ${this.$i18n.t('BEHAVIOUR')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.onDeleteSubTask(data.subtaskID)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
